import "./Header.css";
import React, { useState } from "react";
import HeaderItem from "./HeaderItem";
import SoppyLogo from "../../static/soppy-logo.webp";
import { Link } from "react-router-dom";
import Menu from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import MailIcon from "../../static/contact/mail_icon.webp";
import PhoneIcon from "../../static/contact/phone_icon.webp";
import LocationIcon from "../../static/contact/location_icon.webp";
import InstagramLogo from "../../static/footer/instagram.png";
import FacebookLogo from "../../static/footer/facebook.png";

function Header() {
  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <div className="aboveHeader">🐾 Besplatna dostava za celu Srbiju za iznos iznad 1900din! 🐾 Dostava za Crnu Goru i BiH! 🐾</div>
      <header className="header">
        <div className="mobileHeader">
          <div className="openDrawer" onClick={() => setDrawer(true)}>
            <Menu className="openDrawerIcon" />
          </div>
          <Drawer
            anchor="left"
            open={drawer}
            onClose={() => {
              setDrawer(false);
            }}
          >
            <HeaderItem text={"Početna"} link={""} setDrawer={setDrawer} />
            <HeaderItem text={"Proizvodi"} link={"proizvodi"} setDrawer={setDrawer} />
            <HeaderItem text={"O nama"} link={"o-nama"} setDrawer={setDrawer} />
            <HeaderItem text={"Kontakt"} link={"kontakt"} setDrawer={setDrawer} />
            <div className="header_info" style={{ marginTop: "auto" }}>
              <div className="header_icon header_icon_drawer">
                <img width={38} height={38} src={LocationIcon} alt="location" />
                <p className="contact__p">
                  Branimira Ćosića 3 <br /> 21000 Novi Sad, Srbija
                </p>
              </div>
              <div className="header_icon header_icon_drawer">
                <img width={38} height={38} src={PhoneIcon} alt="telefon" />
                <a className="contact__p" href="tel:+381603859340">
                  +381603859340
                </a>
              </div>
              <div className="header_icon header_icon_drawer">
                <img width={38} height={38} src={MailIcon} alt="mail" />
                <a className="contact__p" href="mailto:soppypet@gmail.com">
                  soppypet@gmail.com
                </a>
              </div>
            </div>
            <div className="footerHeader__logos">
              <a href="https://www.facebook.com/people/Soppy-%C5%A0ampon-Za-Pse/100062934984163/" target="_blank" rel="noreferrer">
                <img src={FacebookLogo} alt="Facebook logo" />
              </a>
              <a href="https://www.instagram.com/soppykozmetikazapse/" target="_blank" rel="noreferrer">
                <img src={InstagramLogo} alt="Instagram logo" />
              </a>
            </div>
          </Drawer>
        </div>
        <HeaderItem additionalClass={"noShowMobile"} text={"Početna"} link={""} />
        <HeaderItem additionalClass={"noShowMobile"} text={"Proizvodi"} link={"proizvodi"} />
        <Link to={"/"}>
          <img src={SoppyLogo} alt="soppy logo" />
        </Link>
        <HeaderItem additionalClass={"noShowMobile"} text={"O nama"} link={"o-nama"} />
        <HeaderItem additionalClass={"noShowMobile"} text={"Kontakt"} link={"kontakt"} />
      </header>
    </>
  );
}

export default Header;
